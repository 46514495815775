<template>
    <form method="get" action="" class="" role="search">
        <input type="text" :placeholder="lang.placeholder" name="query" v-on:change="lookup">
        <button>
            <svg class="icon icon-search" viewBox="0 0 12 12">
                <path d="M7.29289322,8.8009431 L10.4919501,12 L12,10.4919501 L8.8009431,7.29289322 L7.29289322,8.8009431 L7.29289322,8.8009431 Z"></path>
                <path d="M5,8 L5,8 C6.65685425,8 8,6.65685425 8,5 C8,3.34314575 6.65685425,2 5,2 C3.34314575,2 2,3.34314575 2,5 C2,6.65685425 3.34314575,8 5,8 L5,8 Z M5,10 L5,10 C2.23857625,10 0,7.76142375 0,5 C0,2.23857625 2.23857625,0 5,0 C7.76142375,0 10,2.23857625 10,5 C10,7.76142375 7.76142375,10 5,10 L5,10 Z"></path>
            </svg>
        </button>
    </form>
</template>

<script>

    export default {
        props: {
            lang: Object,
            ajax: String
        },
        mounted() {
            console.log(this.ajax)
        },
        methods: {
            lookup: function () {
                return true

            }
        },
    }

</script>