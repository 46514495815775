(function ($) {
    $.fn.mask = function () {
        var div = $('<div>').addClass('mask').css('display', 'none');
        var span = $('<span>');
        var img = $('<img>').attr('src', '/images/spinner.svg');
        span.appendTo(div);
        img.appendTo(div);
        div.appendTo(this);
        div.fadeIn();
        return this;
    };
    $.fn.unmask = function () {
        this.find('.mask').fadeOut(function () { $(this).remove() });
        return this;
    }
})(jQuery);