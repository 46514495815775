window.initSelect = function () {
    $('.selectpicker').selectpicker({
        noneSelectedText: '',
        hideDisabled: true,
        dropupAuto: false
    }).on('changed.bs.select', function () {
        updateSelectLabel($(this));
    }).on('loaded.bs.select', function () {
        updateSelectLabel($(this));
    }).on('refreshed.bs.select', function () {
        updateSelectLabel($(this));
    }).on('show.bs.select', function () {
        updateSelectLabel($(this), true);
    }).on('hide.bs.select', function () {
        updateSelectLabel($(this));
    });
};

var updateSelectLabel = function (el, force) {
    var label = el.closest('.select').find('label');
    if ((el.val() === '' || el.val() == '0') && force !== true) {
        label.removeClass('float');
    } else {
        label.addClass('float');
    }
};

window.initSelect();