import SearchComponent from './components/SearchComponent.vue';
import ModalComponent from './components/ModalComponent.vue';
Vue.component('search-component', SearchComponent);
Vue.component('modal-component', ModalComponent);


// Application bootstrapping
window._      = require('lodash');
window.Popper = require('popper.js').default;
window.$      = window.jQuery = require('jquery');
window.axios  = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// vendor dependencies
require('bootstrap');

// Bootstrap Select
require('bootstrap-select');

// DataTables
require('jszip/dist/jszip');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-fixedheader-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-select-bs4');

// momentjs
window.moment = require('moment');

// toastr
window.toastr = require('toastr');
window.toastr.options = {
    "preventDuplicates": true,
    "preventOpenDuplicates": true
};

// flash
window.flash = require('./utilities/flash');

// flatpickr (date/time picker)
require('flatpickr');

// Sweetalert2
let swal = require('sweetalert2');
window.swal = swal.mixin({
    confirmButtonClass: 'btn btn-primary',
    cancelButtonClass: 'btn btn-link',
    buttonsStyling: false,
    reverseButtons: true,
    showCancelButton: true
});

// JS utilities
$(document).ready(function () {
    require('./utilities/ajax');
    require('./utilities/datatables');
    require('./utilities/forms');
    require('./utilities/mask');
    require('./utilities/modal');
    require('./utilities/nav-toggle');
});

require('./footer_ie_message.js');