    $(document).ready(function () {
		var browser = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1: return "edge";
                case agent.indexOf("edg") > -1: return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
                case agent.indexOf("trident") > -1: return "ie";
                case agent.indexOf("msie") > -1: return "ie";
                case agent.indexOf("firefox") > -1: return "firefox";
                case agent.indexOf("safari") > -1: return "safari";
                default: return "other";
            }
        })(window.navigator.userAgent.toLowerCase());
    // if browser is IE or Old Edge display message
    if(browser == 'edge' || browser == 'ie') {
       $("#footer-ie-message" ).html( "PSA Group no longer supports this browser, please consider using an alternative Chromium based browser.");
    }
});