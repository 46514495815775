export async function call (config) {
    $('.is-invalid').removeClass('is-invalid');

    if (!config.background) {
        config.form.mask();
    }

    let axiosResponse;
    let axiosStatus;

    await axios(config).then(function (response) {
        toastr.success(response.data.message ? response.data.message : 'Record updated');
        axiosResponse            = response.data;
        axiosResponse.statusCode = response.status;
        axiosStatus              = response.status;
    }).catch(function (error) {
        toastr.error('There was an issue...<br>' + error.response.data.message);
        $.each(error.response.data.errors, function (field, error) {
            if ($('input[name="' + field + '"]').length) {
                $('input[name="' + field + '"]').addClass('is-invalid');
                if ($('input[name="' + field + '"]').closest('.flatpickr-wrapper').length) {
                    $('input[name="' + field + '"]').closest('.flatpickr-wrapper').addClass('is-invalid');
                } else if ($('input[name="' + field + '"]').closest('.psa-material').length) {
                    $('input[name="' + field + '"]').closest('.psa-material').addClass('is-invalid');
                }
            } else if ($('select[name="' + field + '"]').length) {
                $('select[name="' + field + '"]').addClass('is-invalid');
                if ($('select[name="' + field + '"]').closest('.psa-material').length) {
                    $('select[name="' + field + '"]').closest('.psa-material').addClass('is-invalid');
                } else if ($('select[name="' + field + '"]').closest('.bootstrap-select').length) {
                    $('select[name="' + field + '"]').closest('.bootstrap-select').addClass('is-invalid')
                } else if ($('select[name="' + field + '[]"]').closest('.bootstrap-select').length) {
                    $('select[name="' + field + '[]"]').closest('.bootstrap-select').addClass('is-invalid')
                }
            } else if ($('input[name="' + field + '[]"]').length) {
                $('input[name="' + field + '[]"]').addClass('is-invalid');
                if ($('input[name="' + field + '[]"]').closest('.psa-material').length) {
                    $('input[name="' + field + '[]"]').closest('.psa-material').addClass('is-invalid');
                }
            } else if ($('textarea[name="' + field + '"]').length) {
                $('textarea[name="' + field + '"]').addClass('is-invalid')
            }
        });
        axiosResponse = false;
        axiosStatus   = error.response.status;
    }).then(function () {
        if (!config.background) {
            config.form.unmask();
        }

        var callback = config.form.data('callback');
        if (callback) {
            window[callback](axiosStatus, axiosResponse);
        }
    });

    return axiosResponse;
}
