<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="modal-vue">
        <div class="modal-dialog" role="alertdialog">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title">{{ store.get('modalTitle') }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div class="modal-body" style="min-height: 200px">
                    <component slot="body" :is="currentBody" :componentData="componentData" :key="componentKey"></component>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-link" @click="modalCancel">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="modalSave">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '../utilities/vueStore.js'

    export default {
        components: {},
        data() {
            return {
                currentBody: false,
                componentData: {},
                title: '',
                store: store,
                componentKey: 0
            }
        },
        mounted() {},
        methods: {
            modalCancel () {
                this.$root.$emit('modalCancel')
            },
            modalSave () {
                this.$root.$emit('modalSave')
            }
        }
    }
</script>

<style scoped>
    .modal-body {
        max-height: 60vh;
        overflow-y: scroll;
    }
</style>
