$(document).ready(function () {
    $('button.burger').on('click', function (e) {
        e.preventDefault();
        $('body').addClass('nav-drawer-open');
    });

    $('.menu-mask').on('click', function (e) {
        e.preventDefault();
        if ($('.side-sub-navigation').hasClass('show')) {
            $('.side-sub-navigation').removeClass('show');
        } else {
            $('body').removeClass('nav-drawer-open');
        }
    });

    $('.side-sub-navigation').on('click', '#close-sub-navigation', function () {
        $('.side-sub-navigation').removeClass('show');
    });

    /**
     * Open a sub menu
     */
    $('aside .dropdown-toggle').on('click', function () {
        $('.side-sub-navigation').html('<button id="close-sub-navigation">Back</button>')

        var id   = $(this).attr('id');
        var menu = $('aside ul[aria-labelledby="' + id + '"]');

        $('.side-sub-navigation').append(menu.clone());
        $('.side-sub-navigation').addClass('show');
    });

    var timeout;
    $('nav.top-navigation .dropdown-toggle').on('mouseenter', function () {
        clearTimeout(timeout);
        $(this).addClass('open');
        let id = $(this).attr('id');
        let menu = $('nav.top-navigation ul[aria-labelledby="' + id + '"]');
        $('nav.top-navigation .dropdown ul').not(menu).fadeOut('fast');
        menu.fadeIn();
    });

    $('nav.top-navigation .dropdown ul').on('mouseenter', function () {
        clearTimeout(timeout);
        $(this).prev().addClass('open');
    });

    $('nav.top-navigation .dropdown-toggle, nav.top-navigation .dropdown ul').on('mouseleave', function () {
        $('nav.top-navigation .dropdown-toggle.open').removeClass('open');
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            $('nav.top-navigation .dropdown ul').fadeOut('fast');
        }, 500);
    });

});
