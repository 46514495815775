import {call} from './psa.js';

$('#app').on('submit', 'form[data-method="ajax"]', function (e) {
    e.preventDefault();

    let method = $(this).find('input[name="_method"]').length ? $(this).find('input[name="_method"]').val() : $(this).attr('method');
    if (method === 'PUT' || method === 'PATCH') {
        method = 'POST';
    }

    let config = {
        form: $(this),
        data: new FormData($(this)[0]),
        url: $(this).attr('action'),
        method: method
    }

    if ($(this).attr('enctype') === 'multipart/form-data') {
        config.headers = { 'Content-Type': 'multipart/form-data' }
    }
    
    let background = $(this).data('background');
    if (background && background === true) {
        config.background = true;
    } else {
        config.background = false;
    }

    call(config);
});