var table = false;

$('#app').on('click', '[data-cmd]', function () {
    table = $(this).closest('.datatable, .dataTable').DataTable();
    var el      = $(this);
    var elTable = el.closest('.datatable, .dataTable');
    var cmd     = el.data('cmd');
    var route   = el.closest('tr').data(cmd);
    if (!route) {
        route = el.data('route');
    }
    if (route.length) {
        switch (cmd) {
            case 'destroy' :
                if (confirm('Are you sure you want to delete this record?')) {
                    axios.delete(route).then((response) => {
                        toastr.success('Record deleted');
                        table.draw();
                    }).catch((error) => {
                        console.log(error);
                        toastr.error(error.response.data);
                    });
                }
                break;

            case 'ajax' :
                let method = el.data('method');
                elTable.mask();
                axios[method](route).then((response) => {
                    toastr.success(response.data.message);
                    axiosResponse            = response.data;
                    axiosResponse.statusCode = response.status;
                    axiosStatus              = response.status;
                    table.draw();
                }).catch((error) => {
                    console.log(error);
                    toastr.error(error.response.data);
                    axiosResponse = false;
                    axiosStatus   = error.response.status;
                }).then(() => {
                    elTable.unmask();
                    let callback = el.data('callback');
                    if (typeof self[callback] === 'function') {
                        self[callback](axiosStatus, axiosResponse);
                    }
                });
                break;

            case 'callback':
                let callback = el.data('callback');
                if (typeof self[callback] === 'function') {
                    self[callback](route, el);
                }
                break;

            default: window.location.href = route; break;
        }

    }
});

$('.datatable').on('init.dt', function (e, settings, json) {
    // init method
});
