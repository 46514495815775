/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';
window.Vue = Vue;

// theme
require('../../vendor/psagroupeuk/theme/resources/theme/psagroupe/js/theme');

const app = new Vue({
    el: '#app'
});

import { Dropzone } from "dropzone";