/**
 * A Global Store object that we can use to set, get and del data
 * If debug is true, we will get a console log of all interactions with the store
 */

export const store = {
    debug: false,
    state: {
        investor: {},
        modalTitle: ''
    },

    set (obj, key, val) {
        if (this.debug) this.log('set', obj, key, val)
        if (key && this.state[obj]) this.state[obj][key] = val
        else if (key) this.state[obj] = { [key]: val }
        else this.state[obj] = val
    },

    get (obj, key, val) {
        if (this.debug) this.log('get', obj, key, val)
        if (key && this.state[obj] && this.state[obj][key]) return this.state[obj][key]
        if (key && val) return val
        if (key) return ''
        if (this.state[obj]) return this.state[obj]
        return val ? val : ''
    },

    del (obj, key) {
        if (this.debug) this.log('del', obj, key)
        if (key && this.state[obj][key]) this.state[obj][key] = null
        else if (this.state[obj]) this.state[obj] = null
    },

    log (method, obj, key, val) {
        console.log('State management event: ' + method);
        console.log(obj, key, val)
    }
}