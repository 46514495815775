// show modals
var container;
var button;
$('#app').on('click', '.btn-modal', function (e) {
    e.preventDefault();
    container = $(this).data("container");
    button    = $(this);
    if ($(this).attr('role') === 'delete') {
        // delete confirmation
        let content  = $(this).data('content');
        let route    = $(this).data('href');
        let redirect = $(this).data('redirect');

        swal.fire({
            title: 'Are you sure you want to delete this record?',
            text: content,
            type: 'error',
            icon: 'error',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-danger'
        }).then(function (result) {
            if (result.value) {
                $('#app').mask();
                axios.delete(route).then((response) => {
                    $('#app').unmask();
                    if (response.data && response.data.message) {
                        toastr.success(response.data.message);
                    } else {
                        toastr.success('Record deleted');
                    }
                    if (self.table) {
                        self.table.draw();
                    } else if (button.closest($('.datatable, .dataTable')).length) {
                        let table = button.closest($('.datatable, .dataTable')).DataTable();
                        table.draw();
                    }
                    if (redirect && redirect === 'reload') {
                        window.location.reload(true);
                    } else if (redirect && redirect.length) {
                        window.location.href = redirect;
                    }
                }).catch((error) => {
                    $('#app').unmask();
                    console.log(error.response);
                    toastr.error(error.response.data.message);
                });
            }
        });
        // $(container).find('.modal-body span').text(content);
        // $(container).modal('show');
        // $(container).find('.btn[role="delete"]').on('click', function () {
        //     axios.delete(route).then((response) => {
        //         toastr.success('Record deleted');
        //         $(container).modal('hide');
        //         if (self.table) {
        //             self.table.draw();
        //         }
        //         if (redirect && redirect === 'reload') {
        //             window.location.reload(true);
        //         } else if (redirect && redirect.length) {
        //             window.location.href = redirect;
        //         }
        //     }).catch((error) => {
        //         console.log(error);
        //         toastr.error(error.response.data);
        //     });
        // });
    } else if ($(this).data('ajax')) {
        var url   = $(this).data('ajax');
        var title = $(this).data('title');
        var size  = $(this).data('size');

        if ($(this).data('footer') && $(this).data('footer') === 'hide') {
            $(container).find('.modal-footer').hide();
        } else {
            $(container).find('.modal-footer').show();
        }

        $(container).find('.modal-title').text(title);
        $(container).find('.modal-body').mask();
        if (size && size.length) {
            $(container).find('.modal-dialog').attr('class', 'modal-dialog modal-' + size);
        }

        $(container).modal('show');

        $.ajax({
            global: false,
            url: url,
            dataType: 'html',
            success: function (html) {
                $(container).find('.modal-body').html(html);
                $(container).find('.modal-body').unmask();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $(container).find('.modal-body').html(jqXHR.responseText);
                $(container).find('.modal-body').unmask();
            }
        })
    } else {
        // populate modal with remote data
        $.ajax({
            url: $(this).data('href'),
            dataType: 'html',
            success: function (html) {
                $(container).html(html).modal('show');
            }
        });
    }

    return false;
});

// floating links
$('a.btn-floating').not('.btn-modal, .burger-wrapper').on('click', function () {
    window.location.href = $(this).attr('href');
});

// submitting forms in modals
import {call} from './psa.js';

$('#modal-ajax button[role="save"]').on('click', function (e) {
    e.preventDefault();

    let form = $('#modal-ajax form');
    let config = {
        form: form,
        data: form.serialize(),
        url: form.attr('action'),
        method: form.find('input[name="_method"]').length ? form.find('input[name="_method"]').val() : form.attr('method'),
        dataType: 'json'
    }

    call(config).then((response) => {
        console.log(response);
        if (response.statusCode < 300 && response.statusCode >= 200) {
            $(container).modal('hide');
            self.table.draw();
        }
    });

});
